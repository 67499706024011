import React from 'react';
import Helmet from 'react-helmet';
import cx from 'classnames';

import style from './contact.module.scss';
import marker from 'assets/icons/icon-aside-contact-pointer.png';
import {ReactComponent as MailIcon} from 'assets/header/icon-menu-mail.svg';
import {ReactComponent as PhoneIcon} from 'assets/header/icon-menu-phone.svg';
import {ReactComponent as NextIcon} from 'assets/icons/icon-next.svg';
export default () => {
  return (
    <div className={style.wrapper}>
      <Helmet>
        <title>Kontakt - Płomyka 54</title>
      </Helmet>
      <div className={style.headingArea}>
        <div className={cx(style.img, 'desktop-only')} />
        <div className={style.contactArea}>
          <div className={style.row}>
            <div>
              <img src={marker} alt="" />
            </div>
            <div>
              <p className="heading-text">PLACE INVEST</p>
              <p className="text">Plac marsz. Józefa Piłsudskiego 3</p>
              <p className="text">00-078 Warszawa</p>
              <p className="text">NIP 5252794657</p>
              <p className="text">REGON 383858588</p>
            </div>
          </div>
          <div className={style.row}>
            <div>
              <PhoneIcon />
            </div>
            <div>
              <a href="tel:732554554" className="text">
                732-554-554
              </a>
            </div>
          </div>
          <div className={style.row}>
            <div>
              <MailIcon />
            </div>
            <div>
              <a href="mailto:sprzedaz@plomyka54.pl" className="text">
                sprzedaz@plomyka54.pl
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className={cx(style.otherInvestments, 'desktop-only')}>
        <a href="http://www.place-invest.com/" className="text" target="_blank" rel="noopener noreferrer">
          Zobacz nasze inne inwestycje
        </a>
        <NextIcon />
      </div>
    </div>
  );
};
