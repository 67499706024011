import React from 'react';
import cx from 'classnames';

import DescriptionPage1 from './DescriptionPage1';
import styles from './technology.module.scss';

import {ReactComponent as WindowsIcon} from 'assets/technology/icon-tech-windows.svg';
import {ReactComponent as BalustradaIcon} from 'assets/technology/icon-tech-balustrada.svg';
import {ReactComponent as ACIcon} from 'assets/technology/icon-tech-klima.svg';
import {ReactComponent as MonitoringIcon} from 'assets/technology/icon-tech-monitoring.svg';
import {ReactComponent as VentIcon} from 'assets/technology/icon-tech-vent.svg';
import {ReactComponent as WaterIcon} from 'assets/technology/icon-tech-water.svg';

export default function Features() {
  return (
    <>
      <div className={cx('mobile-only', styles.page1)} />
      <DescriptionPage1 className="mobile-only"/>
      <div className={styles.page2}>
        <div className={styles.grid}>
          <>
            <div className={cx(styles.cell1, styles.feature)}>
              <WindowsIcon />
              <section>
                <header className="heading-text">Okna</header>
                <ul className="text">
                  <li>stolarka okienna aluminiowa</li>
                  <li>okna przesuwne typu HS</li>
                  <li>okucia antywłamaniowe RC2</li>
                  <li>szkło bezpieczne P4</li>
                  <li>kontaktrony połączone z systemem alarmowym</li>
                  <li>montaż ciepły w strefie ocieplenia</li>
                </ul>
              </section>
            </div>
            <div className={cx(styles.cell2, styles.feature)}>
              <BalustradaIcon />
              <section>
                <header className="heading-text">balustrady</header>
                <ul className="text">
                  <li>żelbetowe obłożone klinkierem Wienerberger Long John Black</li>
                  <li>szklane ze szkła laminowanego hartowanego</li>
                </ul>
              </section>
            </div>
            <div className={cx(styles.cell3, styles.feature)}>
              <WaterIcon />
              <section>
                <header className="heading-text">stacja uzdatniania wody</header>
                <ul className="text">
                  <li>woda po wlocie do budynku zostaje oczyszczona oraz uzdatniona</li>
                  <li>filtracja mechaniczna, odżelazienie, odmanganianie, filtracja węglowa, zmiękczanie</li>
                </ul>
              </section>
            </div>
          </>
          <>
            <div className={cx(styles.cell4, styles.feature)}>
              <VentIcon />
              <section>
                <header className="heading-text">wentylacja mechaniczna</header>
                <ul className="text">
                  <li>system wentylacji mechanicznej</li>
                  <li>rozprowadzenie kanałów do każdego pomieszczenia</li>
                  <li>dodatkowo zastosowano filtry do oczyszczenie i nawilżenia powietrza</li>
                  <li>system rekuperacji</li>
                </ul>
              </section>
            </div>
            <div className={cx(styles.cell5, styles.feature)}>
              <ACIcon />
              <section>
                <header className="heading-text">klimatyzacja</header>
                <ul className="text">
                  <li>lokale wyposażone w klimatyzację</li>
                  <li>możliwość regulacji temperatury i jakości powietrza w każdym pomieszczeniu budynku</li>
                </ul>
              </section>
            </div>
            <div className={cx(styles.cell6, styles.feature)}>
              <MonitoringIcon />
              <section>
                <header className="heading-text">monitoring inwestycji</header>
                <ul className="text">
                  <li>system kamer</li>
                  <li>system czujek ruchu</li>
                  <li>system interwencji mobilnej</li>
                </ul>
              </section>
            </div>
          </>
        </div>
      </div>
    </>
  );
}
