import React, {useState} from 'react';
import {HashRouter, Route, Switch} from 'react-router-dom';
import {CSSTransition, TransitionGroup} from 'react-transition-group';
import cx from 'classnames';

import Header from './components/header/Header';

import HomeContent from './pages/home/HomeContent';
import HomeAside from './pages/home/HomeAside';
import Map from 'components/map/Map';
import LocationAside from './pages/location/LocationAside';
import GalleryAside from './pages/gallery/GalleryAside';
import GalleryContent from './pages/gallery/GalleryContent';
import ProjectionContent from './pages/projections/ProjectionContent';
import ProjectionAside from './pages/projections/ProjectionAside';
import TechnologyAside from './pages/technology/TechnologyAside';
import TechnologyContent from './pages/technology/TechnologyContent';
import ContactAside from './pages/contact/ContactAside';

import GalleryContextProvider from './components/GalleryContextProvider';

import './styles/layout.scss';

const locationKey = (pathname: string) => {
  if (pathname.includes('/rzuty')) {
    return pathname.substring(0, pathname.indexOf('/rzuty') + '/rzuty'.length);
  }
  return pathname;
};

const App: React.FC = () => {
  const [hamburgerOpen, setHamburgerOpen] = useState<boolean>(false);
  return (
    <HashRouter>
      <main>
        <header>
          <Header open={hamburgerOpen} setOpen={setHamburgerOpen} />
        </header>
        <Route
          render={({location}) => (
            <GalleryContextProvider>
              <TransitionGroup className={cx({'nav-open': hamburgerOpen}, 'animated-pages-group')}>
                <CSSTransition classNames="fade" timeout={600} key={locationKey(location.pathname)}>
                  <div className="animation-wrapper">
                    <aside>
                      <Switch location={location}>
                        <Route exact path="/" component={HomeAside} />
                        <Route path="/lokalizacja" component={LocationAside} />
                        <Route path="/okolica" component={GalleryAside} />
                        <Route path="/rzuty" component={ProjectionAside} />
                        <Route path="/technologia" component={TechnologyAside} />
                        <Route path="/kontakt" component={ContactAside} />
                        <Route render={() => 'Nie ma takiej strony'} />
                      </Switch>
                    </aside>
                    <section className="content">
                      <Switch location={location}>
                        <Route exact path="/" component={HomeContent} />
                        <Route path="/lokalizacja" render={() => <Map lat={52.201994} lng={20.921} />} />
                        <Route path="/okolica" component={GalleryContent} />
                        <Route path="/rzuty" component={ProjectionContent} />
                        <Route path="/technologia" component={TechnologyContent} />
                        <Route path="/kontakt" render={() => <Map lat={52.2427796} lng={21.0121965} />} />
                        <Route render={() => 'Nie ma takiej strony'} />
                      </Switch>
                    </section>
                  </div>
                </CSSTransition>
              </TransitionGroup>
            </GalleryContextProvider>
          )}
        />
      </main>
    </HashRouter>
  );
};

export default App;
