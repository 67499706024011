import React from 'react';
import Markdown from 'react-markdown';
import cx from 'classnames';
import styles from './technology.module.scss';
import {description, subtitle, title} from 'cms/technology.json';

type Props = {
  className?: string;
};

export default function DescriptionPage1({className}: Props) {
  return (
    <article className={cx(styles.descriptionPage1, className)}>
      <header>
        <h1 className="head">{title}</h1>
        <h2 className="subhead">
          <Markdown>{subtitle}</Markdown>
        </h2>
      </header>
      <div className={styles.description}>
        <div className="text">
          <Markdown>{description}</Markdown>
        </div>
      </div>
    </article>
  );
}
