import React from 'react';
import cx from 'classnames';

import Bullets from 'components/bullets/Bullets';
import {title, subtitle, description} from 'cms/gallery.json';
import style from './gallery.module.scss';

export default () => {
  return (
    <article className={style.description}>
      <div>
        <header>
          <h1 className="head">{title}</h1>
          <h2 className="subhead">{subtitle}</h2>
        </header>
        <p className={cx('text', style.descriptionText)}>{description}</p>
      </div>
      <footer className="desktop-only">
        <Bullets/>
      </footer>
    </article>
  );
};
