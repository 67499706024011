import React from 'react';
import Bullets from 'components/bullets/Bullets';
import Markdown from 'react-markdown';

import {ReactComponent as OneIcon} from 'assets/icons/icon-aside-one-fill.svg';
import {ReactComponent as PremiumIcon} from 'assets/icons/icon-aside-premium-fill.svg';
import {ReactComponent as TreeIcon} from 'assets/icons/icon-aside-tree-fill.svg';

import {title, subtitle, blocks} from 'cms/home.json';
import styles from './home-aside.module.scss';

const blockIcons = [<OneIcon />, <PremiumIcon />, <TreeIcon />];

export default () => {
  return (
    <article className={styles.wrapper}>
      <div>
        <header>
          <h1 className="head">{title}</h1>
          <h2 className="subhead">{subtitle}</h2>
        </header>
        <div className="desktop-only">
          {blocks.map(({title, description}, num) => (
            <div key={num} className={`${styles.block} text`}>
              {blockIcons[num]}
              <p>
                <span className="heading-text">{title}</span>
              </p>
              <p><Markdown>{description}</Markdown></p>
            </div>
          ))}
        </div>
      </div>
      <div className="desktop-only">
        <Bullets />
      </div>
    </article>
  );
};
