import React from 'react';
import styles from './technology.module.scss';

export default function DescriptionPage2() {
  return (
    <article className={styles.descriptionPage2}>
      <header>
        <h1>
          <p className="subhead">co nas</p>
          <p className="head">wyróżnia</p>
        </h1>
      </header>
    </article>
  );
}
