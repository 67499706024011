export const floorParamToCls: {[floor: string]: string} = {
  garaz: 'page-garage',
  parter: 'page-floor-0',
  pietro: 'page-floor-1',
  poddasze: 'page-floor-2'
};

export function getFloorSegment(index: number) {
  return Object.keys(floorParamToCls)[index];
}
