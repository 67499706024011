import React, {useContext} from 'react';
import Gallery from 'react-image-gallery';
import Markdown from 'react-markdown';

import 'react-image-gallery/styles/css/image-gallery-no-icon.css';
import Bullets from 'components/bullets/Bullets';

import styles from './home-content.module.scss';
import {GalleryContext} from 'components/GalleryContextProvider';
import {blocks, gallery} from 'cms/home.json';

import {ReactComponent as OneIcon} from 'assets/icons/icon-aside-one-fill.svg';
import {ReactComponent as PremiumIcon} from 'assets/icons/icon-aside-premium-fill.svg';
import {ReactComponent as TreeIcon} from 'assets/icons/icon-aside-tree-fill.svg';
import useGallery from 'hooks/useGallery';
import Helmet from 'react-helmet';

const blockIcons = [<OneIcon />, <PremiumIcon />, <TreeIcon />];

export default () => {
  const ref = useGallery(gallery.length);
  const {index, setIndex} = useContext(GalleryContext);

  return (
    <div className={styles.wrapper}>
      <Helmet>
        <title>Płomyka 54</title>
      </Helmet>
      <div className={styles.galleryWrapper}>
        <Gallery
          ref={ref}
          items={gallery.map(({desktop}) => ({original: desktop}))}
          showThumbnails={false}
          showBullets={false}
          showIndex={false}
          showPlayButton
          showNav={false}
          showFullscreenButton={false}
          autoPlay
          slideDuration={700}
          slideInterval={5000}
          onSlide={(currentIndex: number) => {
            if (index !== currentIndex) {
              setIndex(currentIndex);
            }
          }}
        />
        <div className={`${styles.bulletsWrapper} mobile-only`}>
          <Bullets white />
        </div>
      </div>
      <div className={`mobile-only ${styles.mobileBlocks}`}>
        {blocks.map(({title, description}, num) => (
          <div key={num} className={styles.block}>
            {blockIcons[num]}
            <div>
              <strong className="sub-subhead">{title}</strong>
              <Markdown className="text">{description}</Markdown>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
