import React from 'react';
import {Route} from 'react-router-dom';
import cx from 'classnames';

import {ReactComponent as BuildingImage} from 'assets/projections/building.svg';
import styles from './projection-styles.module.scss';
import Bullets from 'components/bullets/Bullets';
import {floorParamToCls, getFloorSegment} from './helpers';

export default () => {
  return (
    <Route
      path="/rzuty/:floor"
      render={({
        history,
        match: {
          params: {floor}
        }
      }) => (
        <div
          className={cx(styles.asideWrapper, 'desktop-only')}
          onClick={(event: any) => {
            const classList = event.target.classList;
            Object.entries(floorParamToCls).forEach(([segment, className]) => {
              if (classList.contains(className.substring(5))) {
                history.push(`/rzuty/${segment}`);
              }
            });
          }}>
          <div className={styles.buildingWrapper}>
            <BuildingImage className={cx(floorParamToCls[floor], styles.buildingImage)} />
          </div>
          <Bullets onChange={index => history.push(`/rzuty/${getFloorSegment(index)}`)} />
        </div>
      )}
    />
  );
};
