import React from 'react';
import Markdown from 'react-markdown';
import cx from 'classnames';

import {ReactComponent as MarkerIcon} from 'assets/icons/icon-aside-pointer-black.svg';
import styles from './location-aside.module.scss';
import {subtitle, title, description, pois} from 'cms/location.json';

export default () => {
  return (
    <article className={styles.wrapper}>
      <div className={styles.headerWrapper}>
        <header>
          <h1 className="head">{title}</h1>
          <h2 className="subhead">{subtitle}</h2>
        </header>
      </div>

      <div className={styles.descriptionWrapper}>
        <MarkerIcon />
        <Markdown source={description} className={cx(styles.markdown, 'text')} />
      </div>

      <div className="desktop-only">
        <div className="heading-text">W sąsiedztwie:</div>
        <ul>
          {pois.map(({title, description}, num) => (
            <li key={num}>
              <div className="text">
                {title}
                {!!description && <span> - {description}</span>}
              </div>
            </li>
          ))}
        </ul>
      </div>
    </article>
  );
};
