import React, {useContext} from 'react';
import cx from 'classnames';

import styles from './bullets.module.scss';
import {GalleryContext} from '../GalleryContextProvider';
import {ReactComponent as PrevIcon} from 'assets/icons/icon-prev.svg';
import {ReactComponent as NextIcon} from 'assets/icons/icon-next.svg';

function nextIndex(index: number, count: number) {
  if (index >= count - 1) {
    return 0;
  }
  return index + 1;
}

function prevIndex(index: number, count: number) {
  if (index <= 0) {
    return count - 1;
  }
  return index - 1;
}

type Props = {
  white?: boolean;
  onChange?: (index: number) => void;
  className?: string;
};

export default ({white, onChange, className}: Props) => {
  const {index, setIndex, count} = useContext(GalleryContext);

  const handleChange = (index: number) => {
    setIndex(index);
    if (onChange) {
      onChange(index);
    }
  };

  return (
    <div className={cx(className, styles.bullets, {[styles.white]: white})}>
      <button onClick={() => handleChange(prevIndex(index, count))} className={styles.navButton}>
        <PrevIcon />
      </button>
      <div>
        {new Array(count).fill(0).map((_, number) => (
          <button
            key={number}
            className={`${styles.bullet} ${number === index ? styles.active : ''}`}
            onClick={() => handleChange(number)}
          />
        ))}
      </div>
      <button onClick={() => handleChange(nextIndex(index, count))} className={styles.navButton}>
        <NextIcon />
      </button>
    </div>
  );
};
