import React, {useContext} from 'react';
import Gallery from 'react-image-gallery';
import 'react-image-gallery/styles/scss/image-gallery.scss';
import cx from 'classnames';

import useGallery from 'hooks/useGallery';
import {GalleryContext} from 'components/GalleryContextProvider';
import style from './gallery.module.scss';
import {gallery} from 'cms/gallery.json';
import Description from './Description';
import {ReactComponent as InstaLogo} from 'assets/header/icon-menu-insta.svg';
import Helmet from 'react-helmet';
import Bullets from 'components/bullets/Bullets';

export default () => {
  const ref = useGallery(gallery.length);
  const {index, setIndex} = useContext(GalleryContext);
  return (
    <div className={style.contentWrapper}>
      <Helmet>
        <title>Okolica - Płomyka 54</title>
      </Helmet>
      <div className={style.galleryWrapper}>
        <Gallery
          ref={ref}
          items={gallery.map(({desktop}, num) => ({
            original: desktop,
            renderItem: () => (
              <div className="image-gallery-image">
                <img src={desktop} alt="" />
                {num === gallery.length - 1 && (
                  <div className={`desktop-only ${style.instaWrapper}`}>
                    <span className="nav-text">
                      <a href="https://www.instagram.com/plomyka54/" target="_blank" rel="noopener noreferrer">
                        Więcej na naszym koncie na Instagramie <InstaLogo />
                      </a>
                    </span>
                  </div>
                )}
              </div>
            )
          }))}
          showThumbnails={false}
          showBullets={false}
          showIndex={false}
          showPlayButton
          showNav={false}
          showFullscreenButton={false}
          autoPlay
          slideDuration={700}
          slideInterval={5000}
          onSlide={(currentIndex: number) => {
            if (index !== currentIndex) {
              setIndex(currentIndex);
            }
          }}
        />

        <div className={cx('mobile-only', style.bulletsWrapper)}>
          <Bullets white />
        </div>
      </div>
      <div className={`mobile-only ${style.descriptionWrapper}`}>
        <Description />
        <div className={style.instaWrapper}>
          <div className="nav-text">
            <a href="https://www.instagram.com/plomyka54/" target="_blank" rel="noopener noreferrer">
              Więcej na naszym koncie na Instagramie
            </a>
            <InstaLogo />
          </div>
        </div>
      </div>
    </div>
  );
};
