import React, {useContext, useEffect} from 'react';
import {RouteComponentProps} from 'react-router';
import cx from 'classnames';

import {GalleryContext} from 'components/GalleryContextProvider';
import level0 from 'assets/projections/level-0.png';
import level1 from 'assets/projections/level-1.png';
import level2 from 'assets/projections/level-2.png';
import level3 from 'assets/projections/level-3.png';
import Bullets from 'components/bullets/Bullets';
import {getFloorSegment} from './helpers';
import styles from './projection-styles.module.scss';
import {levels} from 'cms/projections.json';

const imgSrcByFloor: {[floor: string]: string} = {
  garaz: level0,
  parter: level1,
  pietro: level2,
  poddasze: level3
};

type Props = {
  floor: string;
};

type Level = {
  name: string;
  height: string;
  elements: Array<{
    name: string;
    area?: string;
    additional?: boolean;
  }>;
};

function getIndex(floor: string) {
  return Object.keys(imgSrcByFloor).findIndex(key => key === floor);
}

export default function Projection({
  match: {
    params: {floor}
  },
  history
}: RouteComponentProps<Props>) {
  const {setIndex} = useContext(GalleryContext);

  useEffect(() => {
    setIndex(getIndex(floor));
  }, [setIndex, floor]);

  const src = imgSrcByFloor[floor];
  const level: Level = levels[getIndex(floor)];

  if (src && level) {
    const {name, height, elements} = level;
    return (
      <div className={styles.projectionContent}>
        <div className={styles.imgWrapper}>
          <img src={src} alt="rzut" />

          <div className={cx('mobile-only', styles.bullets)}>
            <Bullets onChange={index => history.push(`/rzuty/${getFloorSegment(index)}`)} />
          </div>
        </div>
        <div className={styles.floorElements}>
          <div className={styles.elements}>
            <div className="heading-text">
              {name}
            </div>
            {height && (
              <div className="heading-text">
                WYSOKOŚĆ {height} m
              </div>
            )}
            {elements
              .filter(({additional}) => !additional)
              .map(({name, area, additional}, num) => (
                <div key={num} className="text">
                  {name} {area && <span> - {area} m²</span>}
                </div>
              ))}
            {elements.filter(({additional}) => additional).length > 0 && <div className="text desktop-only">+</div>}
            {elements
              .filter(({additional}) => additional)
              .map(({name, area, additional}, num) => (
                <div key={num} className="text">
                  {name} {area && <span> - {area} m²</span>}
                </div>
              ))}
          </div>
        </div>
      </div>
    );
  }
  return <span>404 - Nie ma takiej strony</span>;
}
