import React from 'react';
import Description from './Description';
import style from './gallery.module.scss';

export default () => {
  return (
    <div className={`${style.asideWrapper} desktop-only`}>
      <Description/>
    </div>
  );
};
