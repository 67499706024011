import React from 'react';
import {NavLink} from 'react-router-dom';

import style from './header.module.scss';

import {ReactComponent as InstaLogo} from 'assets/header/icon-menu-insta.svg';
import {ReactComponent as EmailLogo} from 'assets/header/icon-menu-mail.svg';
import {ReactComponent as PhoneLogo} from 'assets/header/icon-menu-phone.svg';

type Props = {open: boolean; setOpen: (open: boolean) => void};

export default ({open, setOpen}: Props) => {
  const hide = () => setOpen(false);
  return (
    <div className={style.header}>
      <a className={style.logo} href="http://www.place-invest.com/" target="_blank" rel="noopener noreferrer">
        <img src="/images/logo.png" alt="Place Invest"/>
      </a>
      <div className={style.bg} />
      <ul className={`${style.navList} ${open && style.open} nav-text`}>
        <li>
          <NavLink exact to="/" onClick={hide}>
            Płomyka 54
          </NavLink>
        </li>
        <li>
          <NavLink to="/lokalizacja" onClick={hide}>
            Lokalizacja
          </NavLink>
        </li>
        <li>
          <NavLink to="/okolica" onClick={hide}>
            Okolica
          </NavLink>
        </li>
        <li>
          <NavLink to="/rzuty" onClick={hide}>
            Rzuty
          </NavLink>
        </li>
        <li>
          <NavLink to="/technologia" onClick={hide}>
            Technologia
          </NavLink>
        </li>
        <li>
          <NavLink to="/kontakt" onClick={hide}>
            Kontakt
          </NavLink>
        </li>
        <li className={style.separator} />
        <li className={style.media}>
          <a href="https://www.instagram.com/plomyka54/" target="_blank" rel="noopener noreferrer">
            <InstaLogo />
          </a>
        </li>
        <li className={style.media}>
          <a href="mailto:sprzedaz@plomyka54.pl">
            <EmailLogo />
          </a>
        </li>
        <li className={style.media}>
          <a href="tel:732554554">
            <PhoneLogo />
          </a>
        </li>
      </ul>
      <button
        className={`mobile-only hamburger ${open && 'hamburger--collapse'} is-active`}
        onClick={() => setOpen(!open)}>
        <span className="hamburger-box">
          <span className="hamburger-inner" />
        </span>
      </button>
    </div>
  );
};
