import {useContext, useEffect, useRef} from 'react';
import {GalleryContext} from 'components/GalleryContextProvider';

export default function useGallery(count: number) {
  const ref = useRef<any>();
  const {current} = ref;
  const {index, setIndex, setCount} = useContext(GalleryContext);

  useEffect(() => {
    setIndex(0);
    setCount(count);
  }, [setIndex, setCount, count]);

  useEffect(() => {
    if (current) {
      current.slideToIndex(index);
    }
  }, [index, current]);

  return ref;
}
