import React from 'react';
import GoogleMapReact from 'google-map-react';
import Helmet from 'react-helmet';

import marker from 'assets/icons/icon-map-pointer-yellow.png';
import styles from './map.module.scss';

type Location = {
  lat: number;
  lng: number;
};

const MainMarker = (props: Location) => (
  <div className={styles.marker}>
    <img src={marker} alt="" />
  </div>
);

type Props = {
  lat: number,
  lng: number
};

export default ({lat, lng}: Props) => {
  return (
    <div className={styles.mapContainer}>
      <Helmet>
        <title>Lokalizacja - Płomyka 54</title>
      </Helmet>
      <GoogleMapReact
        bootstrapURLKeys={{key: 'AIzaSyCtS8p4_Fu1t7oq64vdiiJoXgBdEyduOqA'}}
        defaultCenter={{
          lat,
          lng
        }}
        defaultZoom={14}>
        <MainMarker  lat={lat} lng={lng} />
      </GoogleMapReact>
    </div>
  );
};
