import React, {useContext} from 'react';
import {CSSTransition, TransitionGroup} from 'react-transition-group';
import cx from 'classnames';

import styles from './technology.module.scss';
import {GalleryContext} from 'components/GalleryContextProvider';
import Bullets from 'components/bullets/Bullets';
import DescriptionPage1 from './DescriptionPage1';
import DescriptionPage2 from './DescriptionPage2';

export default () => {
  const {index} = useContext(GalleryContext);
  const Page = index === 0 ? DescriptionPage1 : DescriptionPage2;
  return (
    <div className={cx(styles.asideWrapper, 'desktop-only')}>
      <TransitionGroup className={styles.animationWrapper}>
        <CSSTransition classNames="animation" timeout={600} key={index}>
          <Page />
        </CSSTransition>
      </TransitionGroup>
      <Bullets className={styles.bullets} white={index !== 0} />
    </div>
  );
};
