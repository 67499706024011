import React, {useState, SetStateAction} from 'react';

type ContextType = {
  index: number;
  setIndex: (action: SetStateAction<number>) => void;
  count: number;
  setCount: (action: SetStateAction<number>) => void;
};

export const GalleryContext = React.createContext<ContextType>({
  index: 0,
  setIndex: () => {},
  count: 0,
  setCount: () => {}
});

const GalleryContextProvider: React.FC = ({children}) => {
  const [index, setIndex] = useState<number>(0);
  const [count, setCount] = useState<number>(0);

  return (
    <GalleryContext.Provider
      value={{
        index,
        setIndex,
        count,
        setCount
      }}>
      {children}
    </GalleryContext.Provider>
  );
};

export default GalleryContextProvider;
