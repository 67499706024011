import React, {useContext, useEffect} from 'react';
import Helmet from 'react-helmet';
import {Redirect, Route, Switch, RouteComponentProps} from 'react-router-dom';
import {CSSTransition, TransitionGroup} from 'react-transition-group';

import {GalleryContext} from 'components/GalleryContextProvider';
import Projection from './Projection';
import {ReactComponent as Busola} from 'assets/icons/busola.svg';
import styles from './projection-styles.module.scss';

export default ({location}: RouteComponentProps) => {
  const {setCount} = useContext(GalleryContext);

  useEffect(() => {
    setCount(4);
  }, [setCount]);

  return (
    <div className={styles.contentWrapper}>
      <Helmet>
        <title>Rzuty - Płomyka 54</title>
      </Helmet>
      <Busola className={styles.busola}/>
      <Route exact path="/rzuty" render={() => <Redirect to="/rzuty/parter" />} />
      <TransitionGroup className={styles.animated}>
        <CSSTransition classNames="projection-animation" timeout={1600} key={location.pathname}>
          <Switch location={location}>
            <Route path="/rzuty/:floor" component={Projection} />
          </Switch>
        </CSSTransition>
      </TransitionGroup>
    </div>
  );
};
