import React, {useContext, useEffect} from 'react';
import Helmet from 'react-helmet';
import useMedia from 'use-media';

import styles from './technology.module.scss';
import {CSSTransition, TransitionGroup} from 'react-transition-group';
import {GalleryContext} from 'components/GalleryContextProvider';
import Features from './Features';

export default () => {
  const {index, setIndex, setCount} = useContext(GalleryContext);
  const isDesktop = useMedia({minWidth: 820});
  useEffect(() => {
    setIndex(0);
    setCount(2);
  }, [setIndex, setCount]);

  useEffect(() => {
    setIndex(0);
    setCount(isDesktop ? 2 : 1);
  }, [isDesktop, setIndex, setCount]);

  const Page = () =>
    isDesktop && index === 0 ? (
      <div className={styles.mobilePageWrapper}>
        <div className={styles.page1} />
      </div>
    ) : (
      <Features />
    );

  return (
    <div className={styles.contentWrapper}>
      <Helmet>
        <title>Technologia - Płomyka 54</title>
      </Helmet>
      <TransitionGroup className={styles.animationWrapper}>
        <CSSTransition classNames="animation" timeout={600} key={index}>
          <Page />
        </CSSTransition>
      </TransitionGroup>
    </div>
  );
};
